<ng-container [ngModelGroup]="(i).toString()" #roteiroFC="ngModelGroup">
    <div class="content-line" [class.movendo]="movendo">

        <div class="buttons">
            <button class="btn col1" type="button" name="button" [attr.tooltip]="tooltips.edit"
                (click)="irEditor(roteiro)" [disabled]="movendo || roteiro.id == undefined">
                <i class="fas fa-pen"></i>
            </button>
            <button class="botao-ordenar-roteiro btn btn-primary" type="button" name="button" [attr.tooltip]="tooltips.move"
                (click)="mover.emit({})">
                <i class="fas fa-indent"></i>
            </button>
            <button class="btn btn-danger col2" type="button" name="button" [attr.tooltip]="tooltips.delete"
                (click)="excluir.emit(roteiro)" [disabled]="movendo != undefined">
                <i class="fas fa-trash"></i>
            </button>
        </div>
        <!-- {{roteiro | json}} -->
        <h2 class="title" i18n>Roteiro {{roteiro.cod}}</h2>

        <div class="form-section">
            <!-- <label for="cod" i18n>Código</label> -->
            <input class="roteiro-cod-input" type="text" name="cod" [(ngModel)]="roteiro.cod" placeholder="L00, AL00, 000, 00-00" #cod="ngModel" [class.danger]="!cod.valid && !movendo"
            maxlength="8" required pattern="[a-zA-Z0-9 \-_.]*" [notEqualMultiple]="outrosRoteirosComp(roteiro)" [disabled]="movendo != undefined">
            <div class="alert danger" *ngIf="!cod.valid && cod.errors && !movendo">
                <div *ngIf="cod.errors.pattern" i18n>Só são permitidas letras, números, pontos, traços e underlines</div>
                <div *ngIf="cod.errors.required" i18n>Campo obrigatório</div>
                <div *ngIf="cod.errors.maxlength" i18n>Máximo de 8 caracteres</div>
                <div *ngIf="cod.errors.notEqualMultiple" i18n>Não podem existir dois roteiros com o mesmo código</div>
            </div>
        </div>
        <div class="form-section" *ngIf="!roteiro.extra">
            <!-- <label for="cod" i18n>Código</label> -->
            <div class="select-editable">
                <select
                    name="cod_retorno_select"
                    [(ngModel)]="roteiro.cod_retorno"
                >
                    <option value=""></option>
                    <option
                        *ngFor="let cod of roteiros_cods(); let i = index"
                        [value]="cod"
                    >
                        {{ cod }}
                    </option>
                    <!--<option value="R02">R02</option>-->
                </select>
                <input
                    class="roteiro-return-input"
                    type="text"
                    name="cod_retorno"
                    [(ngModel)]="roteiro.cod_retorno"
                    i18n-placeholder
                    placeholder="Sem retorno"
                    #cod_retorno="ngModel"
                    [class.danger]="(!cod_retorno.valid || !retornoValido()) && !movendo"
                    maxlength="8"
                    pattern="[a-zA-Z0-9 \-_.]*"
                    [disabled]="movendo != undefined"
                >
            </div>
            <div class="alert danger" *ngIf="(!cod_retorno.valid || !retornoValido()) && !movendo">
                <div *ngIf="cod_retorno.errors && cod_retorno.errors.pattern" i18n>Só são permitidas letras, números, pontos, traços e underlines</div>
                <div *ngIf="cod_retorno.errors && cod_retorno.errors.maxlength" i18n>Máximo de 8 caracteres</div>
                <div *ngIf="!retornoValido()" i18n>Roteiro de retorno com esse código não existe</div>
            </div>
        </div>
        <div class="form-section">
            <!-- <label for="nome" i18n>Descrição</label> -->
            <input class="roteiro-description-input" type="text" name="nome" [(ngModel)]="roteiro.nome" placeholder="Nome do roteiro" #nome="ngModel" [class.danger]="!nome.valid && !movendo"
            maxlength="35" required i18n-placeholder [disabled]="movendo != undefined">
            <div class="alert danger" *ngIf="!nome.valid && nome.errors && !movendo">
                <div *ngIf="nome.errors.required" i18n>Campo obrigatório</div>
                <div *ngIf="nome.errors.maxlength" i18n>Máximo de 35 caracteres</div>
            </div>
        </div>
        <div class="form-section" *ngIf="projeto?.habilitar_tarifas">
            <input
                #tarif_input
                class="roteiro-tarifa-input"
                type="number"
                step="1"
                min="0"
                max="100"
                name="tarifa_centavos"
                (input)="atualizarTarifa($event)"
                placeholder="Tarifa"
                [class.danger]="!nome.valid && !movendo"
                i18n-placeholder
                [disabled]="movendo != undefined">
        </div>

    </div>
    <!-- <div class="">
        <div>Roteiro Extra <input type="checkbox" name="extra" style="width: unset; display: inline-block"></div>
    </div> -->
</ng-container>
