import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import * as model from '@app/projeto.model';

@Component({
    selector: 'app-projetos-painel-form',
    templateUrl: './projetos-painel-form.component.html',
    styleUrls: ['./projetos-painel-form.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class ProjetosPainelFormComponent implements OnInit {

    public opcoesPossiveis = {
        8  : [32, 48, 64, 80, 96],
        13: [32, 48, 64, 80, 96, 112, 128, 144],
        16: [32, 48, 64, 80, 96, 112, 128, 144, 160],
        19 : [32, 64, 96, 128, 160, 192],
        26 : [32, 64, 96, 128, 160, 192],
        32 : [32, 64, 96, 128, 160, 192],
        64 : [32, 64, 96, 128, 160, 192],
    };

    keys = Object.keys;


    private _painel;
    public oldPainel;

    @Input()
    set painel(painel: model.Painel) {
        this._painel = painel;
        this.oldPainel = JSON.parse(JSON.stringify(painel));
    }
    get painel() {
        return this._painel;
    }

    @Input() outrosPaineis: model.Painel[];
    @Input() i: number;

    // @Output() adaptar = new EventEmitter<model.Painel[]>();

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
    }

    enderecosCan(painel: model.Painel) {
        return this.outrosPaineis.filter(painelComp => painelComp != painel).map(painelComp => +painelComp.enderecoCan);
    }

    // adaptarGrupos() {
    //     console.log(this.painel);
    //     if(this.opcoesPossiveis[+this.painel.dimension_y].indexOf(+this.painel.dimension_x) == -1) {
    //         this.painel.dimension_x = this.opcoesPossiveis[this.painel.dimension_y][0]; // fix para quando não existe um tamanho ao trocar e o angular não percebe que o valor do select mudou
    //     }
    //     this.adaptar.emit([this.painel, this.oldPainel]);
    //     this.oldPainel = JSON.parse(JSON.stringify(this.painel));
    // }

}
