import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import * as model from '@app/projeto.model';
import { PainelApiService } from '@app/services/painel-api.service';
import { ProjetoApiService } from '@app/services/projeto-api.service';
import { RoteiroApiService } from '@app/services/roteiro-api.service';

@Component({
    selector: 'app-roteiros-form',
    templateUrl: './roteiros-form.component.html',
    styleUrls: ['./roteiros-form.component.css'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class RoteirosFormComponent implements AfterViewInit {
    @Input() projeto: model.Projeto;
    @Input() roteiro: model.Roteiro;
    @Input() outrosRoteiros: model.Roteiro[];
    @Input() i: number;
    @Input() movendo: model.Roteiro;

    @Output() excluir = new EventEmitter();
    @Output() mover = new EventEmitter();

    tooltips = {
        'edit': $localize`Editar`,
        'move': $localize`Ordenar`,
        'delete': $localize`Excluir`,
    };

    constructor(
        public projetosApi: ProjetoApiService,
        public roteiroApi: RoteiroApiService,
        public painelApi: PainelApiService,
        public router: Router,
    ) { }

    @ViewChild('tarif_input') public tarif_input: ElementRef;

    ngAfterViewInit() {
        this.update_tarif();
    }

    update_tarif() {
        if (this.tarif_input) {
            this.tarif_input.nativeElement.value = this.formatarTarifa();
        }
    }

    outrosRoteirosComp(roteiro: model.Roteiro) {
        return this.outrosRoteiros.filter(roteiroComp => roteiroComp != roteiro).map(roteiroComp => roteiroComp.cod);
    }

    roteiros_cods() {
        return this.outrosRoteiros.map(roteiroComp => roteiroComp.cod);
    }

    retornoValido() {
        const retorno = this.roteiro.cod_retorno;
        if (!retorno) return true;
        return this.roteiros_cods().find(c => c == retorno) != null;
    }

    async irEditor(r: model.Roteiro) {
        this.roteiroApi.select(r);
        const paineis = await this.painelApi.list_by_parent_id(r.parent_id);
        this.painelApi.select(paineis[0]);
        this.router.navigate(['editor']);
    }

    formatarTarifa() {
        const type = typeof this.roteiro.tarifa_centavos;
        if (type != "number") {
            console.warn('tarifas não são number: ', type);
            return;
        }

        // Deve ser possível facilmente definir o valor como null
        if (!this.roteiro.tarifa_centavos) {
            return null;
        }

        return this.roteiro.tarifa_centavos / 100;
    }

    atualizarTarifa(event: any) {
        let tarifa = null;

        // Deve ser possível facilmente definir o valor como null
        if (event.target.value) {
            tarifa = Math.round(event.target.value * 100);
        }

        console.log("Definindo tarifa para: ", tarifa);
        this.roteiro.tarifa_centavos = tarifa;

        if (tarifa > 100000) {
            this.roteiro.tarifa_centavos = tarifa / 10;
            console.warn("Corrigindo tarifa: ", this.roteiro);
            this.update_tarif();
        }

        if (tarifa < 0) {
            this.roteiro.tarifa_centavos = Math.abs(tarifa);
            console.warn("Corrigindo tarifa: ", this.roteiro);
            this.update_tarif();
        }
    }
}
